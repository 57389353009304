@font-face {
  font-family: "Helvethaica";
  src: local("Helvethaica"), url(./assets/fonts/DBHeaven/DB-Helvethaica-X-v3.2.ttf) format("truetype");
}

@font-face {
  font-family: "Helvethaica-SemiBold";
  src: local("Helvethaica-SemiBold"), url(./assets/fonts/DBHeaven/DB-Helvethaica-X-Med-v3.2.ttf) format("truetype");
}

@font-face {
  font-family: "Helvethaica-Bold";
  src: local("Helvethaica-Bold"), url(./assets/fonts/DBHeaven/DB-Helvethaica-X-Bd.ttf) format("truetype");
}

@font-face {
  font-family: "Helvethaica-Medium";
  src: local("Helvethaica-Medium"), url(./assets/fonts/DBHeaven/DB-Helvethaica-X-Med-v3.2.ttf) format("truetype");
}

button,
input,
select,
textarea,
a,
p,
label,
span,
td,
tr,
div {
  font-family: Helvethaica;
  font-size: 1.125rem;
}

h1,
h2,
h3 {
  font-family: Helvethaica-Bold;
}

h4,
h5 {
  font-family: Helvethaica-Bold;
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
}

.pt-80px {
  padding-top: 80px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

div.text-row {
  .left {
    font-family: Helvethaica-SemiBold !important;
  }

  .right {
    color: #007bff !important;
  }
}

.text-bold {
  font-family: "Helvethaica-SemiBold";
}

.opacity-50 {
  opacity: 0.5;
}

// input[type="number"]::-webkit-inner-spin-button,
// input[type="number"]::-webkit-outer-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

// .custom-number-input input:focus {
//   outline: none !important;
// }

// .custom-number-input button:focus {
//   outline: none !important;
// }

table {
  thead {
    th {
      --tw-border-opacity: 1;
      border-color: #efefef;
      border-width: 1px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  tbody {
    td {
      --tw-border-opacity: 1;
      border-color: #efefef;
      border-width: 1px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

table.nowrap {
  thead {
    th {
      --tw-border-opacity: 1;
      border-color: #efefef;
      border-width: 1px;
      padding-left: 5px;
      padding-right: 5px;
      white-space: nowrap;
    }
  }

  tbody {
    td {
      --tw-border-opacity: 1;
      border-color: #efefef;
      border-width: 1px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.bg-black-overlay {
  background: rgba(0, 0, 0, 0.5);
}


.menu-container {
  position: absolute;
  right: 0px;
  top: 30px;
  background-color: white;
  white-space: nowrap;

  div {
    div {
      border-bottom: 1px solid;
      padding: 5px 10px;
    }

    div:last-child {
      border-bottom: 0px solid;
    }
  }
}

input:read-only {
  background-color: #efefef;
}

button:disabled {
  opacity: 0.2;
}