@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.thumpnailImg {
  max-width: 100px;
  margin: 10px;
}

@layer components {
  .btn-red {
    @apply py-1 px-4 bg-red text-white font-semibold rounded-lg shadow-md hover:bg-red-500 focus:outline-none focus:ring-opacity-75;
  }
  .btn-red-outline {
    @apply py-1 px-4 bg-white text-red border border-red font-semibold rounded-lg shadow-md hover:bg-red-500 focus:outline-none focus:ring-opacity-75;
  }
  .btn-cancal {
    @apply py-1 px-4 bg-gray text-white font-semibold rounded-lg shadow-md hover:bg-red-500 focus:outline-none focus:ring-opacity-75;
  }
  .btn-blue {
    @apply py-1 px-4 bg-blue text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-opacity-75;
  }
  .btn-gray {
    @apply py-1 px-4 bg-white border border-gray rounded-lg shadow-md focus:outline-none focus:ring-opacity-75;
  }
  .btn-green {
    @apply py-1 px-4 bg-green text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-opacity-75;
  }
  .form-control {
    @apply border border-gray-200 rounded w-full h-10 pl-3 pr-3
  }
  .btn-link{
    @apply text-blue-link cursor-pointer
  }
}
